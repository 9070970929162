export default [
  {
    label: 'Home',
    icon: 'bi-house-door-fill',
    route: 'AdminDashboard',
    show_badge: false,
    collection: '',
  },
  {
    label: 'Images',
    icon: 'bi-images',
    route: 'AdminImages',
    show_badge: false,
    collection: '',
  },
  {
    label: 'News',
    icon: 'bi-newspaper',
    route: 'AdminNews',
    show_badge: true,
    collection: 'news',
  },
  {
    label: 'Library',
    icon: 'bi bi-book',
    route: 'AdminLibrary',
    show_badge: true,
    collection: 'library',
  },
  {
    label: 'Users',
    icon: 'i bi-people-fill',
    route: 'AdminUsers',
    show_badge: false,
    collection: '',
  },
  // {
  //   label: 'History',
  //   icon: 'bi bi-clock-history',
  //   route: 'AdminHistory',
  //   show_badge: false,
  //   collection: '',
  // },
];
